const toggleSidebar = () => {
  const btn = document.querySelector(".mobile-menu-button");
  const sidebar = document.querySelector(".sidebar");
  console.log("load")
  // add our event listener for the click
  if (btn) {
    btn.addEventListener("click", () => {
      console.log("ok click", sidebar.classList)
      sidebar.classList.toggle("-translate-x-full");
    });
  }
}

document.addEventListener("turbo:load", () => {
  toggleSidebar()
})